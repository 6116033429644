<template>
  <gf-card>
    <gf-content overflow>
      <template #title>
        <span class="font-weight-normal">{{ $NumberFormat(invoices.map(inv => parseFloat(inv.total)).reduce((o, n) => o + n, 0)) }} of {{ $NumberFormat(so.total) }} invoiced</span>
      </template>
      <template #toolbar>
        <gf-button type="primary" size="sm" v-if="so.invoiced !== 2" @click="newInvoice">New Invoice</gf-button>
      </template>
      <div class="no-invoice" v-if="invoices.length === 0" @click="newInvoice">
        <div class="no-invoice__content">
          <div class="no-invoice__content__left">
            <div class="title">Create A New Invoice</div>
            <div class="content">
              <span class="content__text">Create invoices for all or some items in the order.</span>
              <span class="content__text">You can then print, download the invoice in PDF Format</span>
            </div>
          </div>
          <div class="no-invoice__content__right">
            <div class="icon"><i class="el-icon-edit-outline"></i></div>
          </div>
        </div>
      </div>
      <el-form>
        <div class="invoice" v-for="inv in invoices" :key="inv.id">
          <div class="invoice__content">
            <div class="invoice__content__left" @click="viewInvoice(inv.id)">
              <div class="title">Invoice #{{ inv.si_number }}</div>
              <div class="content">
                <span class="content__text">Total: {{ $NumberFormat(inv.total) }}</span>
              </div>
            </div>
            <div class="invoice__content__right">
              <el-button-group :style="!inv.is_paid ? 'margin-right: 10px' : ''">
                <el-button size="small" @click="viewInvoice(inv.id)">View</el-button>
                <el-button size="small" @click="editInvoice(inv.id)" :disabled="inv.paid > 0">Edit</el-button>
                <el-button size="small" @click="removeInvoice(inv)" :disabled="inv.paid > 0">Delete</el-button>
              </el-button-group>
              <el-popover placement="bottom-end" width="300" trigger="click" popper-class="popper" v-if="!inv.is_paid">
                <div class="popper__content">
                  <div class="popper__content__header">Create Payment</div>
                  <div class="popper__content__body">
                    <el-form-item label="Payment Type" prop="payment_method_id">
                      <el-select v-model="payment.payment_method_id">
                        <el-option v-for="pm in paymentMethods" :key="pm.id" :value="pm.id" :label="pm.name"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="Amount" prop="amount">
                      <el-input v-model="payment.amount"></el-input>
                    </el-form-item>
                    <el-form-item label="Paid On" prop="paid_on">
                      <el-date-picker type="date" size="small" v-model="payment.paid_on" format="dd MMM yyyy" value-format="yyyy-MM-dd" style="width: 100%;">
                      </el-date-picker>
                    </el-form-item>
                    <el-form-item label="Reference" prop="reference">
                      <el-input v-model="payment.reference"></el-input>
                    </el-form-item>
                  </div>
                  <div class="popper__content__footer">
                    <el-button size="small" @click="createPayment(inv)">Create Payment</el-button>
                  </div>
                </div>
                <el-button slot="reference" size="small" @click="clickPay(inv)">Pay</el-button>
              </el-popover>
            </div>
          </div>
        </div>
      </el-form>
    </gf-content>
  </gf-card>
</template>

<style lang="scss">
.no-invoice {
  cursor: pointer;

  &__content {
    display: flex;
    justify-content: space-between;
    border: 1px solid #c4ced1;
    padding: 20px;
    border-radius: 5px;

    &__left {
      .title {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 6px;
      }

      .content {
        font-size: 14px;
        display: flex;
        flex-direction: column;
      }
    }

    &__right {
      font-size: 50px;
      color: #6e6e6e;
    }
  }
}

.invoice {
  cursor: pointer;
  margin-bottom: 10px;

  &__content {
    display: flex;
    align-items: center;
    border: 1px solid #c4ced1;
    padding: 10px 20px;
    border-radius: 5px;
    transition: .2s all;

    &:hover {
      border: 1px solid #409EFF;
      box-shadow: 0 1px 2px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    }

      &__left {
        flex-grow: 1;

      .title {
        font-size: 16px;
        font-weight: 500;
        margin-right: 10px;
      }

      .content {
        font-size: 14px;
        display: flex;
        flex-direction: column;
      }
    }
  }
}
</style>

<script>
import SalesOrderService from '@/services/v1/SalesOrder'
import SalesInvoiceService from '@/services/v1/SalesInvoice'
import PaymentMethodService from '@/services/v1/PaymentMethod'

export default {
  props: {
    updateChild: Boolean
  },
  data () {
    return {
      loading: false,

      so: {
        so_number: null,
        invoiced: null,
        totla: null
      },
      invoices: [],
      payment: {
        sales_order_id: null,
        sales_invoice_id: null,
        si_number: null,
        payment_method_id: null,
        amount: null,
        paid_on: this.$DateISOFormat(new Date()),
        reference: null
      },
      paymentMethods: [],

      // rules
      paymentRules: {
        payment_method_id: [
          { required: true, message: ' ', trigger: 'change' }
        ],
        paid_on: [
          { required: true, message: ' ', trigger: 'change' }
        ],
        amount: [
          { required: true, message: ' ', trigger: 'blur' }
        ]
      }
    }
  },
  watch: {
    updateChild: function () {
      if (this.updateChild) {
        this.$emit('update:updateChild', false)

        // do something
        this.getSalesOrder()
      }
    }
  },
  methods: {
    newInvoice () {
      this.$router.push({ name: 'invoice-new', params: { id: this.$route.params.id } })
    },
    viewInvoice (id) {
      this.$router.push({ name: 'invoice-view', params: { id: this.$route.params.id, iid: id } })
    },
    editInvoice (id) {
      this.$router.push({ name: 'invoice-edit', params: { id: this.$route.params.id, iid: id } })
    },
    removeInvoice (inv) {
      this.$confirm('You are attempting to delete this Invoice. This action cannot be undone.', 'Are you sure?', {
        confirmButtonText: 'Yes, Delete Invoice',
        cancelButtonText: 'Cancel'
      }).then(async () => {
        try {
          await this.soService.deleteInvoice(this.$route.params.id, inv.id)
          this.$message.success(`${inv.si_number} successfully deleted`)
          this.$emit('update:update', true)
          this.getSalesOrder()
        } catch (error) {
          this.$error500()
        }
      }).catch(() => {})
    },
    createPayment (inv) {
      this.$refs.payment.validate()
        .then(async () => {
          try {
            this.payment.sales_order_id = parseInt(this.$route.params.id)
            this.payment.sales_invoice_id = parseInt(inv.id)
            this.payment.si_number = inv.si_number

            this.$sanitize(this.payment)

            await this.soService.createPayment(this.$route.params.id, this.payment)
            this.$message.success(`${inv.si_number} was successfully paid`)

            this.$emit('update:update', true)
            this.getSalesOrder()
          } catch (error) {
            this.$error500()
          }
        })
        .catch(() => {})
    },
    clickPay (inv) {
      this.payment.amount = parseFloat(inv.total) - parseFloat(inv.paid)
    },

    // Service Fetch
    async getSalesOrder () {
      try {
        this.loading = true
        this.title = null
        this.invoices = []

        const soService = new SalesOrderService(this.$route.params.id)
        const result = await soService.getSOandStatus()
        this.so = result.data

        if (this.so.invoiced !== 0) {
          const siService = new SalesInvoiceService(this.$route.params.id)
          const result2 = await siService.getSIandStatus()
          this.invoices = result2.data.rows
        }
      } catch (error) {
        this.$Error(error)
      } finally {
        this.loading = false
      }
    },
    async getPaymentMethods () {
      try {
        const pmService = new PaymentMethodService()
        const result = await pmService.list()
        this.paymentMethods = result.data.rows
      } catch (error) {
        this.$Error(error)
      }
    }
  },
  async mounted () {
    await this.getPaymentMethods()
    await this.getSalesOrder()

    this.$store.dispatch(this.$SET_BREADCRUMB, [
      { title: 'Sales Order', route: '' },
      { title: 'Order' },
      { title: this.so.so_number },
      { title: 'Invoices' }
    ])
  }
}
</script>
